.dashboard-filterer {
  width: 330px;
  background: #ffffff;
}
.dashboard-filterer-small {
  width: 140px;
  margin-right: 35px;
  background: #ffffff;
}
.extra-margin-top {
  margin-top: 35px;
}
.to-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #273047;
  margin-right: 18px;
  margin-left: 18px;
}
.price-range-flex-container {
  display: inline-flex;
  width: 100%;
}
.dashboard-filterer-container {
  display: 'flex' !important;
  flex-wrap: 'wrap' !important;
  border: solid 1px #d8eaf0;
  padding: 12px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
  margin: 8px;
  padding: 24px;
}

.dashboard-filterer-container-map-mode {
  border: solid 1px #d8eaf0;
  border-radius: 8px;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
}

@media screen and (max-width: 600px) {
  .dashboard-filterer {
    flex: 1 0 100%;
    padding: 0 12px 8px 4px;
  }
}
