#top-tool-bar {
  display: flex;
  justify-content: space-between;
}

#top-tool-bar-right-buttons {
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  gap: 12px;
}

#top-tool-search {
  margin-left: 20px;
}
