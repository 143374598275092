@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&&display=swap');

* {
  font-family: 'Outfit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-image: none;
}
